import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

import { stylesHelper } from "@styles/helpers";

import * as styles from "./UserAvatar.styles";

interface Props {
  firstName?: string;
  lastName?: string;
  label?: string;
  color?: keyof typeof AVATAR_COLORS;
  size?: keyof typeof AVATAR_SIZES;
  isImpersonating?: boolean;
}

const AVATAR_SIZES = {
  tiny: "tiny",
  small: "small",
  medium: "medium",
  default: "default",
  large: "large",
  extraLarge: "extraLarge",
};

const AVATAR_COLORS = {
  grey: "grey",
  light: "light",
  white: "white",
};

const UserAvatar: FC<Props> = ({
  firstName,
  lastName,
  label,
  color,
  size,
  isImpersonating,
  children,
  ...rest
}) => {
  const renderGraphic = () => {
    if (firstName && lastName && firstName.length > 0 && lastName.length > 0) {
      const initials = firstName[0] + lastName[0];
      return <span css={styles.text}>{initials.toUpperCase()}</span>;
    }
    return (
      <span>
        <FontAwesomeIcon icon={faUser} />
      </span>
    );
  };

  const conditionalGraphicStyles = () =>
    stylesHelper(styles.graphic, styles.size[size], styles.color[color]);

  return (
    <span css={isImpersonating ? styles.impersonatingWrapper : undefined} {...rest}>
      <span css={conditionalGraphicStyles()} role="img" aria-label={label}>
        {renderGraphic()}
        {children}
      </span>
    </span>
  );
};

UserAvatar.defaultProps = {
  color: "white",
  size: "default",
};

export { UserAvatar, AVATAR_COLORS, AVATAR_SIZES };
