import { useRouter } from "next/router";
import type { FC, ReactElement } from "react";
import { Children, isValidElement, cloneElement } from "react";


interface Props {
  baseStyle: {};
  activeStyle: {};
}

const NavLinksWrapper: FC<Props> = ({ children, baseStyle, activeStyle, ...rest }) => {
  const { asPath } = useRouter();

  const cloneItem = (child: ReactElement) => {
    if (child.props.subLinks) {
      return cloneElement(child, {
        ...child.props,
        css: [baseStyle, child.props.subLinks.some(link => asPath.startsWith(link)) && activeStyle],
      });
    }

    if (child.props.href) {
      return cloneElement(child, {
        ...child.props,
        css: [baseStyle, asPath.startsWith(child.props.href) && activeStyle],
      });
    }
    return child;
  };

  const renderChildren = () => {
    return Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneItem(child);
      }
    });
  };

  return <ul {...rest}>{renderChildren()}</ul>;
};

export { NavLinksWrapper };
