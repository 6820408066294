import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const impersonatingWrapper = css`
  border: 1px solid ${colors.green.base};
  border-radius: 50%;
  display: inline-flex;
  padding: 1px;
`;

export const graphic = css`
  position: relative;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

export const text = css`
  margin-top: -2px;
`;

export const color = {
  grey: css`
    background-color: ${colors.grey.base};
    color: ${colors.white};
  `,
  light: css`
    background-color: ${colors.background};
    color: ${colors.dark};
  `,
  white: css`
    background-color: ${colors.grey.lightFamily.dark};
    color: ${colors.white};
  `,
};

export const size = {
  tiny: css`
    width: 2rem;
    height: 2rem;
    font-size: 0.75rem;
  `,
  small: css`
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.2rem;
  `,
  medium: css`
    width: 3.75rem;
    height: 3.75rem;
    font-size: 1.5rem;
  `,
  default: css`
    width: 5rem;
    height: 5rem;
    font-size: 1.8rem;
  `,
  large: css`
    width: 7.5rem;
    height: 7.5rem;
    font-size: 2rem;
    font-weight: bold;
  `,
  extraLarge: css`
    width: 10rem;
    height: 10rem;
    font-size: 3rem;
    font-weight: bold;

    @media only screen and (max-width: ${dimensions.desktopMax}) {
      width: 8rem;
      height: 8rem;
    }
  `,
};

export const image = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
