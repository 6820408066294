import Link from "next/link";
import { FC, HTMLProps } from "react";

interface Props<T = HTMLAnchorElement> extends HTMLProps<T> {
  href: string;
  title: string;
  subLinks?: string[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NavLink: FC<Props> = ({ href, title, subLinks, ...props }) => {
  return (
    <li>
      <Link href={href} passHref>
        <a {...props}>{title}</a>
      </Link>
    </li>
  );
};

export { NavLink };
