import type { FC, HTMLProps, KeyboardEvent } from "react";

import { useDropdownContext } from "../DropdownContext";

const DropdownTrigger: FC<HTMLProps<HTMLDivElement>> = ({ children, ...rest }) => {
  const {
    onTriggerClicked,
    setMouseOverTrigger,
    setTriggerFocused,
    setTriggerElement,
  } = useDropdownContext();

  const onClickHandler = e => {
    onTriggerClicked();
    if (e) setTriggerElement(e.currentTarget);
  };

  const keyPressHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      onClickHandler(event);
    }
  };

  const onMouseEnterHandler = e => {
    setMouseOverTrigger(true);
    if (e) setTriggerElement(e.currentTarget);
  };

  const onMouseLeaveHandler = () => {
    setMouseOverTrigger(false);
  };

  const onFocusHandler = () => {
    setTriggerFocused(true);
  };

  const onBlurHandler = () => {
    setTriggerFocused(false);
  };

  return (
    <div
      onClick={onClickHandler}
      onKeyPress={keyPressHandler}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      tabIndex={0}
      role="button"
      {...rest}
    >
      {children}
    </div>
  );
};

export { DropdownTrigger };
