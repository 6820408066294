import { css } from "@emotion/react";

import { colors, dimensions, layers } from "@styles";

const navHeight = "4.0625rem";

export const stickyNavigation = css`
  height: ${navHeight};
`;

export const stickyContainer = css`
  position: fixed;
  width: 100%;
  z-index: ${layers.indexNavBarMax};
  display: flex;
  flex-direction: column;
`;

export const mainNav = css`
  width: 100%;
  height: ${navHeight};
  background-color: ${colors.dark};
  display: flex;
  align-items: center;
`;

export const container = css`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding: 0 2.75rem 0 2.5rem;

  @media only screen and (max-width: ${dimensions.tabletMax}) {
    padding: 0 1rem;
  }

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }
`;
