import { FC } from "react";

import * as styles from "./StickyNavigation.styles";

const StickyNavigation: FC = ({ children }) => {
  const renderContainer = () => {
    return <div css={styles.container}>{children}</div>;
  };

  return (
    <div css={styles.stickyNavigation}>
      <div css={styles.stickyContainer}>
        <div css={styles.mainNav}>{renderContainer()}</div>
      </div>
    </div>
  );
};

export { StickyNavigation };
